@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    outline: none !important;
  }
  html {
    font-family: Montserrat, sans-serif;
  }
}
