.input-error .error-forms {
  border: 1px solid red !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* tooltip */
#tooltip.right .tooltip-arrow {
  top: 30%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltipBlock:hover #tooltip {
  display: block !important;
}

/*  */
.number-arrows-remove input::-webkit-outer-spin-button,
.number-arrows-remove input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Select Arrow */
SELECT {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

/* react select css */
.css-1s2u09g-control {
  border: 1px solid #e5e7eb !important;
  border-radius: 8px !important;
}
.css-1s2u09g-control:focus {
  border: 1px solid #f6bf63 !important;
  border-radius: 8px !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
  border: 1px solid #f6bf63 !important;
  border-radius: 8px !important;
}
.css-1pahdxg-control:hover {
  box-shadow: none !important;
  border: 1px solid #f6bf63 !important;
}

.input-error .css-1pahdxg-control,
.input-error .css-1s2u09g-control {
  border: 1px solid red !important;
  border-radius: 8px !important;
}

/* table head fix */
.table-head-fix th {
  background: #f3f4f6;
  position: sticky;
  top: 0;
}

/* custom-scroll */
/* width */
.custom-scroll ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.custom-scroll ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(221, 184, 18);
  border-radius: 10px;
}

/* Handle */
.custom-scroll ::-webkit-scrollbar-thumb {
  background: rgb(215, 215, 215);
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll ::-webkit-scrollbar-thumb:hover {
  background: grey;
}

html {
  scroll-behavior: smooth;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  background-color: #f6bf63;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.mySwiper .swiper-button-next:after {
  content: "next";
  font-size: 15px;
  font-weight: 700;
}
.mySwiper .swiper-button-prev:after {
  content: "prev";
  font-size: 15px;
  font-weight: 700;
}

/* .img-preview img {
  width: 400px !important;
  height: 400px !important;
} */

/* dateRangePicker */

.rs-btn-primary {
  color: #1c1b19 !important;
  background-color: rgb(244, 181, 99) !important;
}

/* table text truncate */
.text-truncation {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
